import { Layout, Divider, Col, Row, Space } from 'antd'
import { EnvironmentOutlined, PhoneOutlined, PrinterOutlined, GlobalOutlined, MailOutlined, PayCircleOutlined, SafetyCertificateOutlined } from '@ant-design/icons'
const { Footer } = Layout


function JSAPEFooter () {

  return (
    <>
      <Divider />
      <Footer>
        <Row><Col span="24">江苏省石化装备行业协会 </Col></Row>

        <Row><Col span="24"><Space align="center"><EnvironmentOutlined />南京市北京西路17号（江苏化工大厦）802室</Space></Col></Row>

        <Row><Col span="24"><Space align="center"><PhoneOutlined /> 025-86915189   <PrinterOutlined /> 025-83232764 </Space></Col></Row>
        <Row><Col span="24"><Space align="center"><GlobalOutlined /> http://www.jsape.com  <MailOutlined /> jsape@sina.com </Space></Col></Row>
        <Row><Col span="24"><Space align="center"><PayCircleOutlined /> 开户行：光大银行南京湖北路支行 帐号：087651120100330001675</Space></Col></Row>
        <Row><Col span="24"><Space align="center"><SafetyCertificateOutlined />苏ICP备18026303号-1 亿商在线技术支持</Space></Col></Row>

      </Footer>
    </>
  )
}

export default JSAPEFooter